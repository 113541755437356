import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/partners';

import CustomerFeatureBenefit from 'components/content-blocks/customer-feature-benefit';
import QuoteBox from 'components/content-blocks/customer-quote-box';
import FeaturesGrid from 'components/content-blocks/features-grid';
import HighlightCards from 'components/content-blocks/highlight';
import MobileFeatures from 'components/content-blocks/mobile-features';
import ProductVideo from 'components/content-blocks/product-video';
import Reviews from 'components/content-blocks/reviews';
import Yoast from 'components/globals/yoast';
import { HeroFeatures } from 'components/hero/features';

import './styles/onfleet.scss';

export default function Onfleet() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "onfleet" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                signup {
                  alert_text
                  launch_modal_button_text
                  legal_text
                  modal_submit_button_text
                  modal_title
                  unique_id
                }
                background_desktop_image {
                  source_url
                  alt_text
                }
                background_mobile_image {
                  source_url
                }
              }

              video_block_1 {
                video_id
                image_desktop {
                  source_url
                }
                image_mobile {
                  source_url
                }
              }
              customer_feature_benefit {
                sub_title
                title
                copy
                customer {
                  business
                  name
                  photo_desktop {
                    source_url
                  }
                  photo_mobile {
                    source_url
                  }
                  since
                }
                stats {
                  value_1
                  descriptor_1
                  value_2
                  descriptor_2
                  value_3
                  descriptor_3
                }
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              features_grid_mobile_1 {
                title
                sub_title
                buttons {
                  button_1 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_2 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_3 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_4 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                }
              }
              customer_quote_1 {
                company_info
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
                quote
              }
              cards_1 {
                header {
                  title
                  sub_title
                  copy
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
            }
          }
        }
      }
      reviews: allWordpressPage(filter: { slug: { eq: "home" } }) {
        edges {
          node {
            acf {
              reviews_section {
                title
                sub_title
                copy
              }
            }
          }
        }
      }
    }  
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const hero = pageContext.hero;
  const cards1 = pageContext.cards_1;
  const productVideo = pageContext.video_block_1;
  const customerCard = pageContext.customer_feature_benefit;
  const featuresGridMobile = pageContext.features_grid_mobile_1;
  const customerQuote = pageContext.customer_quote_1;
  const featuresGrid = pageContext.features_grid;
  const reviews = data.reviews.edges[0].node.acf.reviews_section;

  return (
    <Layout legalText={ hero.signup.legal_text }>
      <Yoast { ...yoast } />
      <div className="onfleet-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroFeatures
          { ...hero }
          uniqueId="onfleetHero"
          data-section="onfleetHero"
          signupModalLaunch={ pageContext.hero.signup.launch_modal_button_text }
        />
        <HighlightCards
          { ...cards1 }
          className="background-green"
          sub_title={ cards1.header.title }
          hasTitle
          hasCopy
          hasImage
        />
        <ProductVideo { ...productVideo } />
        <CustomerFeatureBenefit { ...customerCard[0] } />
        <MobileFeatures { ...featuresGridMobile } />
        <CustomerFeatureBenefit { ...customerCard[1] } className="reverse" />
        <FeaturesGrid { ...featuresGrid[0] } />
        <QuoteBox { ...customerQuote } />
        <FeaturesGrid { ...featuresGrid[1] } className="reverse" />
        <Reviews { ...reviews } />
      </div>
    </Layout>
  );
}